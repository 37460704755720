// -------------------- SASS Imports
@use "sass:color";

// -------------------- Partials Imports
@use "../abstracts/variables" as var;
@use "../abstracts/functions" as func;
@use "../abstracts/mixins" as mix;

.content-indent {
  @include mix.media(var.$desktop) {
    margin-left: func.convert(3);
  }
}

.order {
  @for $i from 1 through 4 {
    &-#{$i} {
      order: #{$i};
    }
  }
}

a[data-disabled] {
  pointer-events: none;
  cursor: default;
  color: var(--clr-cool-blue-400);
  user-select: none;
}

.overflow-hidden {
  overflow: hidden;
}

.ml-auto {
  margin-left: auto;
}

@include mix.media(var.$desktop) {
  .mt-0--on-desktop {
    margin-top: 0 !important;
  }
}

.bg {
  &-center {
    background-position: center;
  }

  &-bottom-right {
    background-position: bottom right;
  }

  &-bottom-left {
    background-position: bottom left;
  }

  &-top-left {
    background-position: top left;
  }

  &-top-right {
    background-position: top right;
  }

  &-contain {
    background-size: contain;
  }

  &-cover {
    background-size: cover;
  }

  &-no-repeat {
    background-repeat: no-repeat;
  }

  &-repeat-x {
    background-repeat: repeat-x;
  }

  &-slide {
    @include mix.media-motion {
      animation: var(--bg-slide-anime-name) var(--bg-slide-x-duration, 100s)
        linear infinite;

      &-x {
        --bg-slide-anime-name: bgSlideX;
      }

      &-x-reverse {
        --bg-slide-anime-name: bgSlideXReverse;
      }

      &-duration--long {
        --bg-slide-x-duration: 300s;

        @include mix.media(var.$tablet-landscape) {
          --bg-slide-x-duration: 200s;
        }
      }
    }
  }

  // Media Queries For .bg-*
  @include mix.media(var.$tablet-portrait) {
    &-top-left {
      &--on-tp {
        background-position: top left;
      }
    }

    &-cover {
      &--on-tp {
        background-size: cover;
      }
    }
  }
  @include mix.media(var.$desktop) {
    &-contain {
      &--on-desktop {
        background-size: contain;
      }
    }
  }

  @include mix.media(var.$desktop-lg) {
    &-bottom-right {
      &--on-lg {
        background-position: bottom right;
      }
    }

    &-bottom-left {
      &--on-lg {
        background-position: bottom left;
      }
    }

    &-contain {
      &--on-lg {
        background-size: contain;
      }
    }
  }

  @include mix.media(var.$desktop-xl) {
    &-contain {
      &--on-xl {
        background-size: contain;
      }
    }
  }
}

.list {
  &-inside {
    list-style-position: inside;
  }

  &-outside {
    @include mix.media(var.$desktop) {
      &--on-desktop {
        list-style-position: outside;
      }
    }
  }
}

.my-tooltip {
  position: fixed;
  top: 0;
  left: 0;
  z-index: var.$z-index-xl;
  text-align: center;
  background-color: var(--clr-shade-light);

  border-radius: func.convert(0.25);
  padding: func.convert(0.5);
  --triangle-pos-x: 50%;
  color: var(--clr-cool-blue-500);

  filter: drop-shadow(
      0 0 func.convert(0.125) color.scale(var.$clr-cool-blue-400, $alpha: -40%)
    )
    drop-shadow(
      0 0 func.convert(0.25) color.scale(var.$clr-cool-blue-400, $alpha: -60%)
    )
    drop-shadow(
      0 0 func.convert(0.5) color.scale(var.$clr-cool-blue-400, $alpha: -80%)
    );

  &::after {
    position: absolute;
    content: "";
    top: 100%;
    left: var(--triangle-pos-x);
    transform: translateX(-50%);

    width: 0;
    height: 0;

    --borderWidth: 10px;
    border: var(--borderWidth) solid transparent;
    border-top-color: var(--clr-shade-light);
  }

  &.top {
    &::after {
      top: unset;
      bottom: 100%;

      border-top-color: transparent;
      border-bottom-color: var(--clr-shade-light);
    }
  }
}

.case-study {
  // Case study utility variables
  @for $i from 1 through 5 {
    &-mt-#{$i} {
      margin-top: var(--case-study-spacings-#{$i});
    }
    &-my-#{$i} {
      margin-top: var(--case-study-spacings-#{$i});
      margin-bottom: var(--case-study-spacings-#{$i});
    }
  }

  &__mockup {
    &--full-w {
      background-color: var(--clr-cool-blue-100);

      height: func.convert(6);

      @include mix.media(var.$tablet-portrait) {
        height: func.convert(9);
      }
      @include mix.media(var.$tablet-landscape) {
        height: func.convert(12);
      }
    }
  }

  &__txt-img-grid-parent {
    display: flex;
    flex-direction: column;

    @include mix.media(var.$desktop) {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__txt-img-grid__img {
    width: func.convert(9);
    align-self: center;

    @include mix.media(var.$tablet-portrait) {
      width: max(35vw, func.convert(13));
    }
    @include mix.media(var.$desktop) {
      width: min(35vw, func.convert(18));
      margin: 0;
    }
  }

  &__flex-wrapper {
    @include mix.media(var.$desktop) {
      &--on-desktop {
        display: flex;
        gap: func.convert(4);
      }
    }
  }
}

// Choosen Color
.colored-boxes__container {
  // Sizing Vairables
  --size: #{func.convert(2)};
  --gap: #{func.convert(0.75)};

  // Color Variables
  --clr-chosen: hsla(218, 80%, 54%, 1);

  --clr-left-1: hsla(274, 24%, 90%, 0.5);
  --clr-left-2: hsla(176, 23%, 85%, 0.5);
  --clr-left-3: hsla(104, 23%, 89%, 0.5);
  --clr-left-4: hsla(54, 23%, 90%, 0.5);
  --clr-left-5: hsla(360, 28%, 90%, 0.5);

  --clr-right-1: hsla(173, 52%, 88%, 0.5);
  --clr-right-2: hsla(346, 36%, 90%, 0.5);
  --clr-right-3: hsla(54, 73%, 92%, 0.5);
  --clr-right-4: hsla(184, 61%, 90%, 0.5);
  --clr-right-5: hsla(284, 44%, 92%, 0.5);

  display: flex;
  width: 100%;
  height: var(--size);
  gap: var(--gap);

  @include mix.media(var.$tablet-portrait) {
    // Sizing Vairables
    --size: #{func.convert(2.5)};
    --gap: #{func.convert(0.75)};
  }

  @include mix.media(var.$tablet-landscape) {
    // Sizing Vairables
    --size: #{func.convert(3)};
    --gap: #{func.convert(1)};
  }

  @include mix.media(var.$desktop) {
    // Sizing Vairables
    --size: #{func.convert(4)};
    --gap: #{func.convert(2)};
  }
}

.colored-boxes__surrounding-clr-left {
  flex: 1.5;
  background-image: repeating-linear-gradient(
    to left,
    var(--clr-left-1) 0 calc(var(--size)),
    transparent calc(var(--size)) calc(var(--size) + var(--gap)),
    var(--clr-left-2) calc(var(--size) + var(--gap))
      calc(2 * var(--size) + var(--gap)),
    transparent calc(2 * var(--size) + var(--gap))
      calc(2 * var(--size) + 2 * var(--gap)),
    var(--clr-left-3) calc(2 * var(--size) + 2 * var(--gap))
      calc(3 * var(--size) + 2 * var(--gap)),
    transparent calc(3 * var(--size) + 2 * var(--gap))
      calc(3 * var(--size) + 3 * var(--gap)),
    var(--clr-left-4) calc(3 * var(--size) + 3 * var(--gap))
      calc(4 * var(--size) + 3 * var(--gap)),
    transparent calc(4 * var(--size) + 3 * var(--gap))
      calc(4 * var(--size) + 4 * var(--gap)),
    var(--clr-left-5) calc(4 * var(--size) + 4 * var(--gap))
      calc(5 * var(--size) + 4 * var(--gap)),
    transparent calc(5 * var(--size) + 4 * var(--gap))
      calc(5 * var(--size) + 5 * var(--gap))
  );
}

.colored-boxes__choosen-clr {
  width: var(--size);
  height: var(--size);
  background-color: var(--clr-chosen);
  position: relative;

  --emoji-size: #{func.pxToRem(32)};
  --emoji-rotate: 20deg;
  --emoji-url: url("/assets/img/chosen-clr-crown-m.png");

  --emoji-animation-str: 1.5s ease-in-out alternate infinite;

  @include mix.media(var.$tablet-portrait) {
    --emoji-size: #{func.pxToRem(54)};
    --emoji-url: url("/assets/img/chosen-clr-crown-tp.png");
  }
  @include mix.media(var.$tablet-landscape) {
    --emoji-size: #{func.pxToRem(60)};
    --emoji-url: url("/assets/img/chosen-clr-crown-tl.png");
  }
  @include mix.media(var.$desktop) {
    --emoji-size: #{func.pxToRem(86)};
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
  }

  &::before {
    width: var(--emoji-size);
    height: calc(var(--emoji-size) * 0.25);
    background-color: var(--clr-cool-blue-600);

    --ds-translate-x: 9.5%;
    --ds-translate-y: 30%;
    --ds-rotate: var(--emoji-rotate);
    transform: translate(var(--ds-translate-x), var(--ds-translate-y))
      rotate(var(--ds-rotate));

    border-radius: 50%;
    --ds-blur: 0.2rem;
    filter: blur(var(--ds-blur));

    @include mix.media-motion {
      --ds-scale-to: 0.925;
      --ds-blur-to: 1.8;
      animation: crown-shadow-scale-up-down var(--emoji-animation-str);
    }

    @include mix.media(var.$tablet-portrait) {
      --ds-blur: 0.4rem;
    }
  }

  &::after {
    width: var(--emoji-size);
    height: var(--emoji-size);

    --crown-translate-x: 25%;
    --crown-translate-y: -72%;
    --crown-rotate: var(--emoji-rotate);
    transform: translate(var(--crown-translate-x), var(--crown-translate-y))
      rotate(var(--crown-rotate));

    background-image: url("/assets/img/chosen-clr-crown-m.png");
    background-size: cover;

    @include mix.media(var.$tablet-portrait) {
      background-image: url("/assets/img/chosen-clr-crown-tp.png");
    }
    @include mix.media(var.$tablet-landscape) {
      background-image: url("/assets/img/chosen-clr-crown-tl.png");
    }

    @include mix.media-motion {
      --crown-translate-x-to: 1.125;
      --crown-translate-y-to: 1.125;
      animation: crown-up-n-down var(--emoji-animation-str);
    }
  }
}

.colored-boxes__surrounding-clr-right {
  flex: 1;
  background-image: repeating-linear-gradient(
    to right,
    var(--clr-right-1) 0 calc(var(--size)),
    transparent calc(var(--size)) calc(var(--size) + var(--gap)),
    var(--clr-right-2) calc(var(--size) + var(--gap))
      calc(2 * var(--size) + var(--gap)),
    transparent calc(2 * var(--size) + var(--gap))
      calc(2 * var(--size) + 2 * var(--gap)),
    var(--clr-right-3) calc(2 * var(--size) + 2 * var(--gap))
      calc(3 * var(--size) + 2 * var(--gap)),
    transparent calc(3 * var(--size) + 2 * var(--gap))
      calc(3 * var(--size) + 3 * var(--gap)),
    var(--clr-right-4) calc(3 * var(--size) + 3 * var(--gap))
      calc(4 * var(--size) + 3 * var(--gap)),
    transparent calc(4 * var(--size) + 3 * var(--gap))
      calc(4 * var(--size) + 4 * var(--gap)),
    var(--clr-right-5) calc(4 * var(--size) + 4 * var(--gap))
      calc(5 * var(--size) + 4 * var(--gap)),
    transparent calc(5 * var(--size) + 4 * var(--gap))
      calc(5 * var(--size) + 5 * var(--gap))
  );
}

.project__in-development {
  > :not(h2, p, [data-bgset]) {
    position: relative;
    border-color: var(--clr-cool-blue-100);

    &::before,
    &::after {
      position: absolute;
    }

    &::before {
      content: "";
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      backdrop-filter: blur(2px);
    }

    &::after {
      content: "In Developement";
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: var(--clr-cool-blue-600);
      padding: 0.8em;
      border: 2px solid var(--clr-shade-light);
      backdrop-filter: blur(5px);
      width: max-content;
      text-align: center;
    }
  }

  h2,
  p {
    color: inherit;
    position: relative;
    z-index: var.$z-index-base;
  }
}

// CSS Loader
.my-css-loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--clr-cool-blue-500);
  z-index: var.$z-index-2xl;

  transform: scaleX(1);
  transform-origin: right;

  &.loaded {
    animation: slideOut 800ms cubic-bezier(0.77, 0, 0.18, 1) forwards;

    .my-css-loader__ellipse-wrapper {
      --scale: 0;
    }
  }
  &.active {
    .my-css-loader__ellipse-wrapper {
      > * {
        animation: my-css-loader var(--loader-duration)
          cubic-bezier(0, 0.2, 0.8, 1) infinite;
      }
      > :nth-child(2) {
        animation-delay: calc(var(--loader-duration) / 2.5);
      }
    }
  }

  &__ellipse-wrapper {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(var(--scale, 1));
    transition: transform 150ms cubic-bezier(0.77, 0, 0.18, 1);
    width: func.convert(3);
    height: func.convert(3);
  }

  &__ellipse-wrapper > * {
    position: absolute;
    border: 0.1rem solid var(--clr-shade-light);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);

    opacity: 1;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    --loader-duration: 2s;
  }
}
