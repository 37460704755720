// -------------------- Partials Imports
@use "../abstracts/variables" as var;
@use "../abstracts/mixins" as mix;

// -------------------- Typeface import (Poppins)
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

// -------------------- Base Typography
:root {
  --base-font-size: #{var.$base-font-size-m};
  --base-line-height: #{var.$base-line-height-m}; // 26px

  @include mix.media(var.$tablet-portrait) {
    --base-font-size: #{var.$base-font-size-tp};
    --base-line-height: #{var.$base-line-height-tp}; // 28px
  }

  @include mix.media(var.$tablet-landscape) {
    --base-font-size: #{var.$base-font-size-tl};
    --base-line-height: #{var.$base-line-height-tl}; // 30px
  }
}

.h1 {
  text-transform: capitalize;
  font-weight: 600;
}
.h2 {
  text-transform: capitalize;
  &--medium {
    font-weight: 500;
  }
  &--semiBold {
    font-weight: 600;
  }
}
.cta {
  text-transform: uppercase;
}
.nav-link {
  font-weight: 400;
}
.text-right {
  text-align: right;
}
.text-underline {
  text-decoration: underline;
}

html {
  font-size: calc(var(--base-font-size) / 16 * 100%);
}

@include mix.typescale-for-all-media;

body {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}
