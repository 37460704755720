// -------------------- SASS Imports
@use "sass:color";

// -------------------- Partials Imports
@use "../abstracts/variables" as var;
@use "../abstracts/functions" as func;
@use "../abstracts/mixins" as mix;

.btn {
  display: inline-block;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: transparent;
  border-radius: 50%;
  outline-color: transparent;
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: transparent;

  --clr-btn-bg: transparent;
  background-color: var(--clr-btn-bg);
  --speed-n-func: 250ms ease-in-out;
  transition: background-color var(--speed-n-func);

  width: func.convert(3.5);
  height: func.convert(3.5);

  --dashoffset: 0;
  --dasharray: 14 20.5;
  --width: 1.5;
  --radius: calc(50% - var(--width) * 1px);

  --btn-label-transitionDuration: 100ms;
  --btn-tick-transitionDuration: 150ms;
  --btn-label-transitionDelay: var(--btn-tick-transitionDuration);
  --btn-tick-transitionDelay: var(--btn-label-transitionDuration);

  &:hover {
    --color: var(--clr-cool-blue-400);
  }

  @include mix.media(var.$tablet-portrait) {
    --btn-label-transitionDuration: 150ms;
    --btn-tick-transitionDuration: 200ms;

    width: func.convert(4);
    height: func.convert(4);

    @include mix.media(var.$tablet-mh-sm, "max-height") {
      width: func.convert(3.5);
      height: func.convert(3.5);
    }
  }
  @include mix.media(var.$desktop) {
    --width: 2;

    width: func.convert(5);
    height: func.convert(5);

    @include mix.media(var.$tablet-mh-sm, "max-height") {
      width: func.convert(4.375);
      height: func.convert(4.375);
    }
  }
  @include mix.media-hover {
    &:is(:hover, :focus-visible) {
      --dashoffset: 20;
    }
  }

  & > * {
    pointer-events: none;
    user-select: none;
  }

  &__label {
    transition: transform var(--btn-label-transitionDuration)
      var(--btn-label-transitionDelay) ease-in-out;
  }

  &__tick {
    position: absolute;
    top: 50%;
    left: 50%;
    --offsetX: #{func.pxToRem(0)};
    --offsetY: #{func.pxToRem(8)};
    --translateX: calc(-50% + var(--offsetX));
    --translateY: calc(-50% + var(--offsetY));
    --scale: 0;
    --rotate: 45deg;
    transform: translate(var(--translateX), var(--translateY))
      scale(var(--scale)) rotate(var(--rotate));

    transition: transform var(--btn-tick-transitionDuration)
      var(--btn-tick-transitionDelay) ease-in-out;

    --tick-size: 36;
    width: func.pxToRem(var(--tick-size));
    height: func.pxToRem(var(--tick-size));

    @include mix.media-hover("none") {
    }

    @include mix.media(var.$tablet-portrait) {
      --tick-size: 44;
      --offsetY: #{func.pxToRem(14)};

      @include mix.media(var.$tablet-mh-sm, "max-height") {
        --tick-size: 38;
      }
    }
    @include mix.media(var.$tablet-landscape) {
      --tick-size: 60;

      @include mix.media(var.$tablet-mh-sm, "max-height") {
        --tick-size: 46;
      }
    }

    path {
      fill: #1e222f;
    }
  }

  &__border {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    stroke-dashoffset: var(--dashoffset);
    stroke-dasharray: var(--dasharray);
    stroke-width: var(--width);
    stroke: var(--color);

    @include mix.media-motion {
      transition: stroke-dashoffset var(--speed-n-func),
        stroke-dasharray var(--speed-n-func), stroke-width var(--speed-n-func),
        stroke var(--speed-n-func), r var(--speed-n-func);
    }

    circle {
      r: var(--radius);
      transform-box: fill-box;
      transform-origin: center;

      @include mix.media-motion {
        transition: transform var(--speed-n-func);
      }
    }
  }

  &:active {
    .btn__border {
      circle {
        transform: scale(0.8);
      }
    }
  }

  &--neutral,
  &--copied {
    & .btn__border {
      @include mix.media-motion {
        animation: spin 5s linear infinite;
      }
    }
    &:is(:hover, :focus-visible) {
      --turns: -1turn;
    }
  }

  &--neutral {
    --color: var(--clr-cool-blue-200);

    &:focus-visible {
      --color: var(--clr-primary-500);
    }
  }

  &--copied {
    --color: var(--clr-primary-500);
    --clr-border-bg: #{color.scale(var.$clr-primary-500, $alpha: -85%)};
    --btn-label-transitionDelay: 0ms;
    --btn-tick-transitionDelay: var(--btn-label-transitionDuration);
    --clr-btn-bg: #{color.scale(var.$clr-primary-500, $alpha: -90%)};

    &:is(:hover, :focus-visible) {
      --color: var(--clr-primary-500);
    }

    .btn__label {
      transform: scale(0);
    }
    .btn__tick {
      --offsetX: 0px;
      --offsetY: 0px;
      --scale: 1;
      --rotate: 0deg;
    }
  }
}
