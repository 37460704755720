// -------------------- Partials Imports
@use "../abstracts/variables" as var;
@use "../abstracts/functions" as func;
@use "../abstracts/mixins" as mix;

/* For WebKit browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: var(--clr-cool-blue-200);
}

::-webkit-scrollbar-thumb {
  background-color: var(--clr-cool-blue-400);
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--clr-cool-blue-500);
}

/* For Firefox */
/* Track */
html {
  scrollbar-width: thin;
  scrollbar-color: var(--clr-cool-blue-400) var(--clr-cool-blue-200);
}
