// -------------------- Partials Imports
@use "../abstracts/variables" as var;
@use "../abstracts/functions" as func;
@use "../abstracts/mixins" as mix;

.gbs {
  &__gbs-pattern {
    background-image: url("/assets/placeholders/gbs-pattern-m--sm.png");

    height: func.convert(2);

    @include mix.media(var.$tablet-portrait) {
      background-image: url("/assets/placeholders/gbs-pattern-tp--sm.png");
      height: func.convert(3);
    }
    @include mix.media(var.$tablet-landscape) {
      background-image: url("/assets/placeholders/gbs-pattern-tl--sm.png");
      height: func.convert(5);
    }
  }

  &__template-sites {
    background-image: url("/assets/placeholders/gbs-template-sites-m--sm.png");

    @include mix.media(var.$tablet-portrait) {
      background-image: url("/assets/placeholders/gbs-template-sites-tp--sm.png");
    }
    @include mix.media(var.$tablet-landscape) {
      background-image: url("/assets/placeholders/gbs-template-sites-tl--sm.png");
    }
  }

  &__multiple-layouts {
    background-image: url("/assets/placeholders/gbs-multiple-layouts-m--sm.png");

    @include mix.media(var.$tablet-portrait) {
      background-image: url("/assets/placeholders/gbs-multiple-layouts-tp--sm.png");
    }
    @include mix.media(var.$tablet-landscape) {
      background-image: url("/assets/placeholders/gbs-multiple-layouts-tl--sm.png");
    }
  }

  &__multiple-layout-variations {
    background-image: url("/assets/placeholders/gbs-multiple-layout-variations-m--sm.png");

    @include mix.media(var.$tablet-portrait) {
      background-image: url("/assets/placeholders/gbs-multiple-layout-variations-tp--sm.png");
    }
    @include mix.media(var.$tablet-landscape) {
      background-image: url("/assets/placeholders/gbs-multiple-layout-variations-tl--sm.png");
    }
  }

  &__wireframe {
    --lazy-w--m: 468;
    --lazy-h--m: 910;
    --lazy-w--tp: 728;
    --lazy-h--tp: 1401;
    --lazy-w--tl: 1080;
    --lazy-h--tl: 2100;
  }

  &__primary-clr.colored-boxes__choosen-clr {
    --clr-chosen: var(--clr-primary-gbs);
  }

  &__typography {
    --lazy-w--m: 617;
    --lazy-h--m: 182;
    --lazy-w--tp: 1234;
    --lazy-h--tp: 364;
    --lazy-w--tl: 2523;
    --lazy-h--tl: 720;
  }

  &__design-system {
    background-image: url("/assets/placeholders/gbs-design-system-m--sm.png");

    @include mix.media(var.$tablet-portrait) {
      background-image: url("/assets/placeholders/gbs-design-system-tp--sm.png");
    }
    @include mix.media(var.$tablet-landscape) {
      background-image: url("/assets/placeholders/gbs-design-system-tl--sm.png");
    }
  }

  &__final-design {
    background-image: url("/assets/placeholders/gbs-final-design-m--sm.png");

    @include mix.media(var.$tablet-portrait) {
      background-image: url("/assets/placeholders/gbs-final-design-tp--sm.png");
    }
    @include mix.media(var.$tablet-landscape) {
      background-image: url("/assets/placeholders/gbs-final-design-tl--sm.png");
    }
  }

  &__next-case-study {
    background-image: url("/assets/placeholders/gbs-next-case-mockup-m--sm.png");

    @include mix.media(var.$tablet-portrait) {
      background-image: url("/assets/placeholders/gbs-next-case-mockup-tp--sm.png");
    }
    @include mix.media(var.$tablet-landscape) {
      background-image: url("/assets/placeholders/gbs-next-case-mockup-tl--sm.png");
    }
  }
}
