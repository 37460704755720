// -------------------- Partials Imports
@use "../abstracts/variables" as var;
@use "../abstracts/functions" as func;
@use "../abstracts/mixins" as mix;

.hero-sec {
  // margin-top: func.convert(var(--combined-mt));
  padding-top: func.convert(var(--combined-mt));
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);

  padding-bottom: func.convert(var(--section-padding));

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    --pt: 0.75;
    padding-top: func.convert(var(--pt));

    @include mix.mixMedia(
      var.$tablet-mw-sm,
      "min-width",
      var.$tablet-mh-sm,
      "max-height"
    ) {
      --pt: 0.125;
    }

    @include mix.media(var.$tablet-portrait) {
      --pt: 1;

      @include mix.media(var.$tablet-mh-sm, "max-height") {
        --pt: 0.125;
      }
    }
    @include mix.media(var.$tablet-landscape) {
      --pt: 1.5;
      overflow: unset;

      @include mix.media(var.$tablet-mh-sm, "max-height") {
        --pt: 0.25;
      }
    }
    @include mix.media(var.$desktop) {
      @include mix.media(var.$tablet-mh-sm, "max-height") {
        --pt: 0.25;
      }
    }
  }

  &__img {
    --size: 364;
    width: func.pxToRem(var(--size));
    height: func.pxToRem(var(--size));
    pointer-events: none;
    user-select: none;

    > img {
      width: 100%;
    }

    @include mix.mixMedia(
      var.$tablet-mw-sm,
      "min-width",
      var.$tablet-mh-sm,
      "max-height"
    ) {
      --size: 330;
    }

    @include mix.media(var.$tablet-portrait) {
      --size: 560;

      @include mix.media(var.$tablet-mh-sm, "max-height") {
        --size: 360;
      }
    }
    @include mix.media(var.$tablet-landscape) {
      @include mix.media(var.$tablet-mh-sm, "max-height") {
        --size: 400;
        transform: translateX(#{func.convert(-1)});
      }
    }
    @include mix.media(var.$desktop) {
      --size: 750;
      transform: translateX(#{func.convert(-1.5)});

      @include mix.media(var.$tablet-mh-sm, "max-height") {
        --size: 480;
        transform: translateX(#{func.convert(-1)});
      }
    }
  }

  .container-md {
    @include mix.media(var.$tablet-landscape) {
      @include mix.media(var.$tablet-mh-sm, "max-height") {
        display: flex;
        justify-content: space-between;
        width: 100%;
        position: absolute;
        left: 50%;
        top: calc(func.convert(8) + func.convert(var(--pt)));
        transform: translateX(-50%);
      }
    }

    @include mix.media(var.$desktop) {
      display: flex;
      justify-content: space-between;
      width: 100%;
      position: absolute;
      left: 50%;
      top: calc(func.convert(18.5) + func.convert(var(--pt)));
      transform: translateX(-50%);

      @include mix.media(var.$tablet-mh-sm, "max-height") {
        top: calc(func.convert(10.25) + func.convert(var(--pt)));
      }
    }
  }

  &__headings {
    margin-top: func.convert(1);

    @include mix.mixMedia(
      var.$tablet-mw-sm,
      "min-width",
      var.$tablet-mh-sm,
      "max-height"
    ) {
      margin-top: func.convert(0.25);
    }

    @include mix.media(var.$tablet-portrait) {
      @include mix.media(var.$tablet-mh-sm, "max-height") {
        order: 2;
        margin-top: func.convert(0.125);
      }
    }

    @include mix.media(var.$desktop) {
      order: 2;
      margin-top: 0;
      margin-right: min(2vw, #{func.convert(2)});

      @include mix.media(var.$tablet-mh-sm, "max-height") {
        margin-right: min(2vw, #{func.convert(3)});
      }
    }

    p,
    h1 {
      text-align: center;

      @include mix.media(var.$tablet-landscape) {
        @include mix.media(var.$tablet-mh-sm, "max-height") {
          text-align: left;
        }
      }

      @include mix.media(var.$desktop) {
        text-align: left;
      }
    }
    p {
      color: var(--clr-cool-blue-400);

      @include mix.media(var.$tablet-landscape) {
        @include mix.media(var.$tablet-mh-sm, "max-height") {
          margin-left: func.pxToRem(5);
        }
      }

      @include mix.media(var.$desktop) {
        margin-left: func.pxToRem(5);
      }
    }
  }

  &__cta {
    margin-top: func.convert(0.5);
    margin-left: auto;
    margin-right: auto;

    @include mix.mixMedia(
      var.$tablet-mw-sm,
      "min-width",
      var.$tablet-mh-sm,
      "max-height"
    ) {
      margin-top: func.convert(0.25);
    }

    @include mix.media(var.$tablet-portrait) {
      @include mix.media(var.$tablet-mh-sm, "max-height") {
        margin-top: func.convert(0.125);
      }
    }
    @include mix.media(var.$tablet-landscape) {
      @include mix.media(var.$tablet-mh-sm, "max-height") {
        margin: 0;
        margin-left: min(10vw, #{func.convert(5)});
      }
    }

    @include mix.media(var.$desktop) {
      margin: 0;
      margin-left: min(5vw, #{func.convert(3)});

      @include mix.media(var.$tablet-mh-sm, "max-height") {
        margin-left: min(10vw, #{func.convert(5.5)});
      }
    }
  }
}
