// -------------------- Partials Imports
@use "../abstracts/variables" as var;
@use "../abstracts/functions" as func;

// -------------------- SASS Mixins

// SASS Media Queries
@mixin media($breakpoint, $defaultMedia: "min-width") {
  @media screen and (#{$defaultMedia}: $breakpoint) {
    @content;
  }
}
@mixin mixMedia(
  $breakpoint1,
  $defaultMedia1: "min-width",
  $breakpoint2,
  $defaultMedia2: "max-width"
) {
  @media screen and (#{$defaultMedia1}: $breakpoint1) and (#{$defaultMedia2}: $breakpoint2) {
    @content;
  }
}
@mixin media-hover($val: "hover") {
  @media (hover: #{$val}) {
    @content;
  }
}
@mixin media-motion($val: "no-preference") {
  @media (prefers-reduced-motion: #{$val}) {
    @content;
  }
}
@mixin media-hover-motion($hover: "hover", $motion: "no-preference") {
  @media (hover: $hover) and (prefers-reduced-motion: $motion) {
    @content;
  }
}

// Double Baseline Grid
@mixin dbl-baseline-grid() {
  background-image: linear-gradient(
    to bottom,
    rgba(0, 170, 255, 0.3) 1px,
    transparent 1px calc(var(--base-line-height) / 2 * 1rem),
    rgba(0, 170, 255, 0.2) calc(var(--base-line-height) / 2 * 1rem),
    transparent calc(var(--base-line-height) / 2 * 1rem + 1px)
      calc(var(--base-line-height) * 1rem)
  );

  background-size: 100% calc(var(--base-line-height) * 1rem);
  background-repeat: repeat;
}

// Type Scale
@mixin typescale($type, $prop) {
  #{$type} {
    @if nth($prop, 1) != null {
      font-size: func.ms(nth($prop, 1));
    }
    @if nth($prop, 2) != null {
      line-height: func.convert(nth($prop, 2));
    }

    @if nth($prop, 3) != null {
      letter-spacing: nth($prop, 3) * 1em;
    }

    @if nth($prop, 4) != null {
      max-width: func.convert(nth($prop, 4));
    }
    @if nth($prop, 5) != null {
      color: nth($prop, 5);
    }
  }
}

@mixin typescale-for-all-media {
  @each $type, $breakpoints in var.$types {
    @if (map-get($breakpoints, "sm") != null) {
      @include typescale($type, map-get($breakpoints, "sm"));
    }

    @if (map-get($breakpoints, "md") != null) {
      @include media(var.$tablet-portrait) {
        @include typescale($type, map-get($breakpoints, "md"));
      }
    }

    @if (map-get($breakpoints, "lg") != null) {
      @include media(var.$tablet-landscape) {
        @include typescale($type, map-get($breakpoints, "lg"));
      }
    }

    @if (map-get($breakpoints, "xl") != null) {
      @include media(var.$desktop) {
        @include typescale($type, map-get($breakpoints, "xl"));
      }
    }

    @if (map-get($breakpoints, "2xl") != null) {
      @include media(var.$desktop-lg) {
        @include typescale($type, map-get($breakpoints, "2xl"));
      }
    }
  }
}

@mixin grid-spacings-scale {
  @each $size, $spacings in var.$case-study-spacings {
    @if (map-get($spacings, "sm") != null) {
      --case-study-spacings-#{$size}: #{func.convert(
          #{map-get($spacings, "sm")}
        )};
    }

    @if (map-get($spacings, "md") != null) {
      @include media(var.$tablet-portrait) {
        --case-study-spacings-#{$size}: #{func.convert(
            #{map-get($spacings, "md")}
          )};
      }
    }

    @if (map-get($spacings, "lg") != null) {
      @include media(var.$tablet-landscape) {
        --case-study-spacings-#{$size}: #{func.convert(
            #{map-get($spacings, "lg")}
          )};
      }
    }
  }
}

// Screen Readers
@mixin sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  // clip: rect(0, 0, 0, 0);
  clip-path: polygon(0 0, 0 0, 0 0, 0 0);
  white-space: nowrap;
  border-width: 0;
}

@mixin not-sr-only {
  position: static;
  width: auto;
  height: auto;
  padding: 0;
  margin: 0;
  overflow: visible;
  // clip: auto;
  clip-path: none;
  white-space: normal;
}
