// -------------------- Partials Imports
@use "../abstracts/variables" as var;
@use "../abstracts/functions" as func;
@use "../abstracts/mixins" as mix;

.watermark {
  position: fixed;
  pointer-events: none;
  user-select: none;
  z-index: var.$z-index-n1;

  --top: #{func.convert(6.5)}; // 169px
  top: var(--top);
  left: 0;
  width: 100%;
  --height: 102;
  height: func.pxToRem(var(--height));

  $postfix: m;
  --bg: var(--url);
  --bg-pos-x: #{func.pxToRem(-35.75)};
  background-image: var(--bg);
  background-repeat: repeat-x;
  background-size: auto 100%;
  background-position-x: var(--bg-pos-x);

  @include mix.media-motion {
    --gap: 0.7s;
    background-position: left 0%;
    animation: slideEnterX var(--gap) ease-in-out,
      bgSlideX 100s var(--gap) linear infinite;

    @include mix.media-hover {
      left: 100%;
      animation: bgSlideX 100s linear infinite;
      animation-play-state: paused;
    }

    @include mix.media(var.$tablet-portrait) {
      --gap: 0.8s;
    }
    @include mix.media(var.$tablet-landscape) {
      --gap: 1s;
    }
  }

  @include mix.mixMedia(
    var.$tablet-mw-sm,
    "min-width",
    var.$tablet-mh-sm,
    "max-height"
  ) {
    --height: 110;
    --bg-pos-x: #{func.pxToRem(-42)};
    --top: #{func.convert(5.75)};
  }

  @include mix.media(var.$tablet-portrait) {
    --height: 176;
    --bg-pos-x: #{func.pxToRem(-49)};
    $postfix: tp;
    --top: #{func.convert(9)}; // 252px

    @include mix.media(var.$tablet-mh-sm, "max-height") {
      --height: 130;
      --bg-pos-x: #{func.pxToRem(-35)};
      --top: #{func.convert(6)};
    }
  }

  @include mix.media(var.$tablet-landscape) {
    @include mix.media(var.$tablet-mh-sm, "max-height") {
      --height: 150;
      --bg-pos-x: #{func.pxToRem(-42)};
      --top: #{func.convert(5.75)};
    }
  }
  @include mix.media(var.$desktop) {
    --height: 260;
    --bg-pos-x: #{func.pxToRem(-60)};
    $postfix: tl;
    --top: #{func.convert(11)}; // 330px

    @include mix.media(var.$tablet-mh-sm, "max-height") {
      --height: 200;
      --bg-pos-x: #{func.pxToRem(-50)};
      --top: #{func.convert(6.25)};
    }
  }

  // Creating classes for each watermark img for each size
  @each $class, $prefix in var.$watermark-prefixes {
    &--#{$class} {
      --url: url("#{$prefix}-m.png");
    }

    @include mix.media(var.$tablet-portrait) {
      &--#{$class} {
        --url: url("#{$prefix}-tp.png");
      }
    }

    @include mix.media(var.$desktop) {
      &--#{$class} {
        --url: url("#{$prefix}-tl.png");
      }
    }
  }
}
