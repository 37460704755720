// -------------------- Partials Imports
@use "../abstracts/variables" as var;
@use "../abstracts/functions" as func;
@use "../abstracts/mixins" as mix;

.about-sec {
  padding-top: func.convert(var(--section-padding));
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);

  .container-lg {
    @include mix.media(var.$desktop) {
      padding-right: func.convert(2);
    }
    @include mix.media(var.$desktop-lg) {
      padding-right: func.convert(3);
    }
  }

  &__wrapper {
    @include mix.media(var.$desktop) {
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      gap: clamp(func.convert(1), 5vw, func.convert(3));
    }

    & .container-md {
      &:nth-child(2) {
        padding: unset;
      }

      @include mix.mixMedia(
        var.$tablet-mw-sm,
        "min-width",
        var.$tablet-mh-sm,
        "max-height"
      ) {
        &:nth-child(2) {
          padding: 0 var(--padding-x);
        }
      }
      @include mix.media(var.$tablet-portrait) {
        &:nth-child(2) {
          padding: 0 var(--padding-x);
        }
      }
      @include mix.media(var.$tablet-landscape) {
        &:nth-child(2) {
          margin-left: unset;
        }
      }
      @include mix.media(var.$desktop) {
        max-width: unset;
        margin: unset;
        padding: unset;
        flex: 1;

        &:nth-child(2) {
          max-width: min(100%, func.convert(20));
          padding: unset;
        }
      }
    }
  }

  &__texts {
    display: flex;
    flex-direction: column;

    gap: func.convert(1);
    @include mix.media(var.$tablet-portrait) {
      gap: func.convert(1.25);
    }
  }

  &__title {
    @include mix.media(var.$mobile-xs, "max-width") {
      br {
        display: none;
      }
    }
    @include mix.media(var.$mobile-md) {
      br {
        display: none;
      }
    }
  }

  &__desc {
    display: flex;
    flex-direction: column;
    gap: func.convert(0.75);

    @include mix.media(var.$tablet-portrait) {
      gap: func.convert(1);
    }
  }

  &__contact {
    align-self: flex-start;

    &.target__border {
      --dasharray: 1000px;
      --duration: 2s;
      --delay: 1s;
      --size: #{func.pxToRem(-20)};

      @include mix.media(var.$mobile-sm, "max-width") {
        --size: #{func.pxToRem(-15)};
      }
    }
  }

  &__social-links {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: func.convert(9);

    @include mix.media(var.$tablet-portrait) {
      width: func.convert(11);
      margin-top: func.convert(0.25);
    }
    @include mix.media(var.$desktop) {
      width: func.convert(10.5);
    }

    button,
    a {
      text-decoration: underline;
    }

    div {
      & + * {
        position: relative;
        --circleSize: 3.29;
        --leftVal: -15.36;

        &::before {
          content: "";
          position: absolute;
          width: func.pxToRem(var(--circleSize));
          height: func.pxToRem(var(--circleSize));
          border-radius: 50%;
          background-color: var(--clr-cool-blue-300);

          left: func.pxToRem(var(--leftVal));
          top: 50%;
          transform: translate(-50%, -50%);
        }

        @include mix.media(var.$tablet-portrait) {
          --circleSize: 4.72;
          --leftVal: -27.89;
        }
        @include mix.media(var.$desktop) {
          --circleSize: 7.08;
          --leftVal: -23.46;
        }
      }
    }
  }

  &__img {
    position: relative;
    z-index: var.$z-index-n2;
    pointer-events: none;
    user-select: none;
    margin-top: func.convert(1.75);

    @include mix.mixMedia(
      var.$tablet-mw-sm,
      "min-width",
      var.$tablet-mh-sm,
      "max-height"
    ) {
      max-width: func.pxToRem(450);
    }

    @include mix.media(var.$tablet-portrait) {
      margin-top: func.convert(1.5);
    }
    @include mix.media(var.$tablet-landscape) {
      max-width: func.pxToRem(600);
      margin-top: func.convert(1.5);

      @include mix.media(var.$tablet-mh-sm, "max-height") {
        max-width: func.pxToRem(450);
      }
    }
    @include mix.media(var.$desktop) {
      max-width: unset;
      margin-top: 0;
    }

    picture {
      max-width: unset;
      width: 100%;
    }

    img {
      width: 100%;
      max-width: unset;
      object-fit: cover;
      object-position: bottom;

      @include mix.media(var.$desktop) {
        height: min(100vh, func.pxToRem(1024));

        @include mix.media(var.$tablet-mh-sm, "max-height") {
          height: max(100vh, func.pxToRem(650));
          width: 80%;
        }
      }
    }
  }
}
