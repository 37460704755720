// -------------------- Partials Imports
@use "../abstracts/variables" as var;
@use "../abstracts/functions" as func;
@use "../abstracts/mixins" as mix;

@include mix.media-motion {
  @include mix.media-hover {
    #smooth-scroll-container {
      overflow: hidden;
      position: fixed;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      & > #smooth-scroll-content {
        display: flow-root;
        overflow: auto;
        width: 100%;
      }
    }

    .scroller-blocker {
      position: fixed !important;
      height: 100% !important;
    }
  }
}
