// -------------------- SASS Imports
@use "sass:color";

// -------------------- Partials Imports
@use "../abstracts/variables" as var;
@use "../abstracts/functions" as func;
@use "../abstracts/mixins" as mix;

// -------------------- Base CSS
:root {
  // Grid Spacings
  @include mix.grid-spacings-scale;

  // Global Variables
  --nav-top-hero-top: 1;
  --header-nav-height: 0.75;
  --section-padding: 3.5;
  --combined-mt: calc(var(--nav-top-hero-top) + var(--header-nav-height));

  @include mix.media(var.$mobile-sm, "max-width") {
    --header-nav-height: 1.75;
  }

  @include mix.mixMedia(
    var.$tablet-mw-sm,
    "min-width",
    var.$tablet-mh-sm,
    "max-height"
  ) {
    --nav-top-hero-top: 0.5;
    --section-padding: 3;
  }

  @include mix.media(var.$tablet-portrait) {
    --header-nav-height: 1;
    --section-padding: 4.5;

    @include mix.media(var.$tablet-mh-sm, "max-height") {
      --nav-top-hero-top: 0.75;
      --section-padding: 3.5;
    }
  }

  @include mix.media(var.$tablet-landscape) {
    --section-padding: 6;

    @include mix.media(var.$tablet-mh-sm, "max-height") {
      --section-padding: 3.5;
    }
  }
}

* {
  outline-width: func.pxToRem(2);
  outline-color: color.scale(var.$clr-primary-500, $alpha: -30%);
  outline-offset: func.pxToRem(5);

  &:focus-visible {
    outline-style: solid;
  }
}

html {
  --scroll-padding-block: 2.5;
  scroll-padding-block: func.convert(var(--scroll-padding-block));

  @include mix.mixMedia(
    var.$tablet-mw-sm,
    "min-width",
    var.$tablet-mh-sm,
    "max-height"
  ) {
    --scroll-padding-block: 2;
  }

  @include mix.media(var.$mobile-sm, "max-width") {
    --scroll-padding-block: 3;
  }
}

::selection {
  color: var(--clr-cool-blue-500);

  --bgSelectColor: #{color.scale(var.$clr-cool-blue-400, $alpha: -50%)};
  background-color: var(--bgSelectColor);
}

a,
button:not(.btn) {
  text-decoration: none;
  display: block;
  cursor: pointer;

  @include mix.media-motion {
    transition: color 150ms ease-in-out;

    * {
      transition: color 150ms ease-in-out;
    }
  }

  :is(&:hover, &:hover *) {
    color: var(--clr-cool-blue-400);
    fill: var(--clr-cool-blue-400);
  }
}

span {
  display: inline-block;
}

button {
  border: none;
  background-color: transparent;
  cursor: pointer;
}
