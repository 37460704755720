// -------------------- Partials Imports
@use "../abstracts/variables" as var;
@use "../abstracts/functions" as func;
@use "../abstracts/mixins" as mix;

.geodaka {
  &__ui-principles {
    --lazy-w--m: 308;
    --lazy-h--m: 106;
    --lazy-w--tp: 616;
    --lazy-h--tp: 175;
    --lazy-w--tl: 1260;
    --lazy-h--tl: 360;
  }

  &__old-design {
    --lazy-w--m: 520;
    --lazy-h--m: 1000;
    --lazy-w--tp: 756;
    --lazy-h--tp: 1484;
    --lazy-w--tl: 1080;
    --lazy-h--tl: 2190;
  }

  &__wireframe {
    --lazy-w--m: 468;
    --lazy-h--m: 936;
    --lazy-w--tp: 728;
    --lazy-h--tp: 1456;
    --lazy-w--tl: 1080;
    --lazy-h--tl: 2160;
  }

  &__primary-clr.colored-boxes__choosen-clr {
    --clr-chosen: var(--clr-primary-geodaka);
  }

  &__typography {
    --lazy-w--m: 616;
    --lazy-h--m: 182;
    --lazy-w--tp: 1232;
    --lazy-h--tp: 364;
    --lazy-w--tl: 2520;
    --lazy-h--tl: 720;
  }

  &__first-iteration {
    background-image: url("/assets/placeholders/geodaka-first-iteration-m--sm.png");

    @include mix.media(var.$tablet-portrait) {
      background-image: url("/assets/placeholders/geodaka-first-iteration-tp--sm.png");
    }
    @include mix.media(var.$tablet-landscape) {
      background-image: url("/assets/placeholders/geodaka-first-iteration-tl--sm.png");
    }
  }

  &__final-design {
    background-image: url("/assets/placeholders/geodaka-final-design-m--sm.png");

    @include mix.media(var.$tablet-portrait) {
      background-image: url("/assets/placeholders/geodaka-final-design-tp--sm.png");
    }
    @include mix.media(var.$tablet-landscape) {
      background-image: url("/assets/placeholders/geodaka-final-design-tl--sm.png");
    }
  }

  &__next-case-study {
    background-image: url("/assets/placeholders/geodaka-next-case-mockup-m--sm.png");

    @include mix.media(var.$tablet-portrait) {
      background-image: url("/assets/placeholders/geodaka-next-case-mockup-tp--sm.png");
    }
    @include mix.media(var.$tablet-landscape) {
      background-image: url("/assets/placeholders/geodaka-next-case-mockup-tl--sm.png");
    }
  }
}
