// -------------------- SASS Imports
@use "sass:math";

// -------------------- Partials Imports
@use "../abstracts/variables" as var;
@use "../abstracts/functions" as func;
@use "../abstracts/mixins" as mix;

.work-sec {
  padding: func.convert(var(--section-padding)) 0;
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);

  &__project-row {
    display: flex;
    flex-direction: column;
    --spacing: 1.5;
    gap: func.convert(var(--spacing));

    & + & {
      margin-top: func.convert(var(--spacing));
    }

    @include mix.media(var.$tablet-portrait) {
      --spacing: 2;
    }

    @include mix.media(var.$desktop) {
      --spacing: 3;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__project {
    @include mix.media(var.$tablet-portrait) {
      align-self: flex-start;
      &:nth-child(even) {
        align-self: flex-end;
      }
    }
    @include mix.media(var.$desktop) {
      &:nth-child(even) {
        align-self: unset;
      }

      &-#{2} {
        margin-top: func.convert(7);
      }
      &-#{4} {
        margin-top: func.convert(2);
      }
      &-#{5} {
        margin-top: func.convert(-5.5);
      }
    }
    @include mix.media(var.$desktop-lg) {
      &-#{2} {
        margin-top: func.convert(8);
      }
      &-#{5} {
        margin-top: func.convert(-6.5);
      }
    }

    // Creating classes for each image for tp and tl breakpoints
    @each $num, $sizes in var.$img-sizes {
      @each $point, $values in $sizes {
        @if $point == "md" {
          @include mix.media(var.$tablet-portrait) {
            &-#{$num} > &-img {
              width: func.convert(
                math.div(
                  nth($values, 1),
                  (var.$base-line-height-tp * var.$base-font-size-tp)
                )
              );
              height: func.convert(
                math.div(
                  nth($values, 2),
                  (var.$base-line-height-tp * var.$base-font-size-tp)
                )
              );
            }
          }
        }
        @if $point == "xl" {
          @include mix.media(var.$desktop-lg) {
            &-#{$num} > &-img {
              width: func.convert(
                math.div(
                  nth($values, 1),
                  (var.$base-line-height-tl * var.$base-font-size-tl)
                )
              );
              height: func.convert(
                math.div(
                  nth($values, 2),
                  (var.$base-line-height-tl * var.$base-font-size-tl)
                )
              );
            }
          }
        }
      }
    }
  }

  &__project-img {
    height: func.convert(12);
    background-color: var(--clr-cool-blue-100);
    border: func.pxToRem(2) solid var(--clr-cool-blue-300);
    overflow: hidden;

    @include mix.media(var.$mobile-sm, "max-width") {
      height: func.convert(11.125);
    }
    @include mix.media(var.$tablet-portrait) {
      border-width: func.pxToRem(2.5);
    }

    @include mix.media(var.$tablet-landscape) {
      border-width: func.pxToRem(3);
    }

    > picture {
      max-width: unset;
      width: 100%;
      height: 100%;

      img {
        max-width: unset;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }

  &__project-title {
    margin-top: func.convert(0.25);

    @include mix.media(var.$tablet-portrait) {
      margin-top: func.convert(0.5);
    }
  }
}
