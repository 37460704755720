// -------------------- Partials Imports
@use "../abstracts/variables" as var;
@use "../abstracts/functions" as func;
@use "../abstracts/mixins" as mix;

img:is(.lazyload, .lazyloading) {
  max-width: unset;
  width: 100%;
}

.lazyloading {
  &.blur-transition--base:not(.lazyloaded, [data-pseudo-bg]) {
    filter: blur(func.convert(0.5));
  }

  &.blur-transition--base[data-pseudo-bg]::after {
    filter: blur(func.convert(0.5));
  }

  &.blur-transition--sm[data-pseudo-bg]::after {
    filter: blur(func.convert(0.125));
  }
}

.lazyloaded {
  transition: filter 500ms ease-in-out;

  &[data-pseudo-bg] {
    transition: unset;

    &::after {
      transition: filter 500ms ease-in-out;
    }
  }
}

.ratio-container {
  position: relative;
  display: block;

  /* padding-bottom or aspect ration is calculated in other css files */
  --lazy-w--m: 1;
  --lazy-h--m: 1;
  --lazy-w: var(--lazy-w--m);
  --lazy-h: var(--lazy-h--m);

  &::after {
    content: "";
    display: block;
    aspect-ratio: var(--lazy-w) / var(--lazy-h);

    @supports not (aspect-ratio: 1) {
      width: 100%;
      height: 0;
      padding-bottom: calc(var(--lazy-h) / var(--lazy-w) * 100%);
    }
  }

  @include mix.media(var.$tablet-portrait) {
    --lazy-w--tp: var(--lazy-w--m);
    --lazy-h--tp: var(--lazy-h--m);
    --lazy-w: var(--lazy-w--tp);
    --lazy-h: var(--lazy-h--tp);
  }
  @include mix.media(var.$tablet-landscape) {
    --lazy-w--tl: var(--lazy-w--tp);
    --lazy-h--tl: var(--lazy-h--tp);
    --lazy-w: var(--lazy-w--tl);
    --lazy-h: var(--lazy-h--tl);
  }
  @include mix.media(var.$desktop) {
    --lazy-w--desktop: var(--lazy-w--tl);
    --lazy-h--desktop: var(--lazy-h--tl);
    --lazy-w: var(--lazy-w--desktop);
    --lazy-h: var(--lazy-h--desktop);
  }
}

.ratio-container img,
.ratio-container picture,
.ratio-container iframe,
.ratio-container video {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  display: block !important;
}
