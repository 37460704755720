// -------------------- Partials Imports
@use "../abstracts/variables" as var;
@use "../abstracts/functions" as func;
@use "../abstracts/mixins" as mix;

.page-transit-elem {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--clr-cool-blue-500);
  z-index: var.$z-index-2xl;
  transform: scaleX(0);
}
