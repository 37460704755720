// -------------------- Partials Imports
@use "../abstracts/variables" as var;
@use "../abstracts/functions" as func;
@use "../abstracts/mixins" as mix;

.feedback-sec {
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);

  &__feedbacks {
    position: relative;
    min-height: max(100vh, 30rem);
  }

  &__feedback {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }

  &__text > p {
    --clr-main: var(--clr-cool-blue-500);
    --clr-secondary: var(--clr-cool-blue-400);

    span {
      display: inline;
    }

    &::before {
      content: "\201C";
    }
    &::after {
      content: "\201D";
    }

    hanging-punctuation: first;

    @supports not (hanging-punctuation: first) {
      text-indent: -0.675em;
    }

    .hide-text {
      @include mix.sr-only;

      &.from-tp {
        @include mix.not-sr-only;
      }
    }

    .main-text {
      color: var(--clr-main);

      &.from-tp {
        color: unset;
      }
    }

    @include mix.media(var.$tablet-portrait) {
      .main-text {
        &.m-only {
          color: unset;
        }

        &.from-tp {
          color: var(--clr-main);
        }
      }

      .hide-text {
        &.mb-only {
          @include mix.not-sr-only;
        }

        &.from-tp {
          @include mix.sr-only;
        }
      }
    }
  }

  &__info {
    margin-top: func.convert(1);
  }

  &__client {
    color: var(--clr-cool-blue-500);
  }
  &__company {
    color: var(--clr-cool-blue-400);
  }
}
