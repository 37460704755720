// -------------------- SASS Imports
@use "sass:math";

// -------------------- Breakpoints
$mobile-xs: math.div(300, 16) * 1em;
$mobile-sm: math.div(350, 16) * 1em;
$mobile-md: math.div(440, 16) * 1em;
$tablet-portrait: math.div(600, 16) * 1em;
$tablet-landscape: math.div(900, 16) * 1em;
$tablet-mh-sm: math.div(680, 16) * 1em;
$tablet-mw-sm: math.div(500, 16) * 1em;
$desktop: math.div(1200, 16) * 1em;
$desktop-lg: math.div(1400, 16) * 1em;
$desktop-xl: math.div(2500, 16) * 1em;

// -------------------- Colors
$clr-primary-500: hsla(208, 100%, 53%, 1);
$clr-shade-light: hsl(0, 0%, 100%, 1);
$clr-darkpink-500: hsla(320, 79%, 60%, 1);
$clr-cool-blue-100: hsla(228, 22%, 95%, 1);
$clr-cool-blue-200: hsla(222, 22%, 91%, 1);
$clr-cool-blue-300: hsla(224, 22%, 86%, 1);
$clr-cool-blue-400: hsla(225, 22%, 69%, 1);
$clr-cool-blue-500: hsla(224, 23%, 28%, 1);
$clr-cool-blue-600: hsla(226, 22%, 15%, 1);

// -------------------- Font sizes
$base-font-size-m: 16;
$base-line-height-m: 1.625; // 26px
$base-font-size-tp: 18;
$base-line-height-tp: 1.555555555; // 28px
$base-font-size-tl: 20;
$base-line-height-tl: 1.5; // 30px

// Values you can choose from
// to maintain vertical rhythm according to line-height -
// 0, 0.125, 0.25, 0.375, 0.5, 0.625, 0.75, 0.875, 1

$ms-ratio: 1.333; // Perfect-fourth

// format = breakpoint: (font-size, line-height, letter-spacing, max-width)
// Add 'null' if you don't want to modify that value (i.e. it's previous breakpoint's value will be taken)
$types: (
  ".h1": (
    sm: (
      4,
      2.25,
      -0.0275,
      19.5,
      var(--clr-cool-blue-600),
    ),
    md: (
      null,
      2.375,
      -0.025,
      21.5,
      null,
    ),
    lg: (
      null,
      2.5,
      -0.0275,
      25.5,
      null,
    ),
    xl: null,
    "2xl": null,
  ),
  ".h2": (
    sm: (
      3,
      1.625,
      -0.025,
      15.5,
      var(--clr-cool-blue-600),
    ),
    md: (
      null,
      1.75,
      null,
      16.5,
      null,
    ),
    lg: (
      null,
      1.75,
      null,
      17,
      null,
    ),
    xl: null,
    "2xl": null,
  ),
  ".h3": (
    sm: (
      2,
      1.5,
      null,
      18,
      var(--clr-cool-blue-400),
    ),
    md: (
      null,
      1.625,
      null,
      24,
      null,
    ),
    lg: (
      null,
      1.75,
      null,
      25,
      null,
    ),
    xl: null,
    2xl: null,
  ),
  ".p1": (
    sm: (
      0,
      1,
      null,
      15.5,
      var(--clr-cool-blue-600),
    ),
    md: (
      null,
      null,
      null,
      16,
      null,
    ),
    lg: (
      null,
      null,
      null,
      17,
      null,
    ),
    xl: null,
    "2xl": null,
  ),
  ".p2-label": (
    sm: (
      -1,
      0.625,
      null,
      10.25,
      var(--clr-cool-blue-400),
    ),
    md: (
      null,
      0.75,
      null,
      10.625,
      null,
    ),
    lg: (
      null,
      null,
      null,
      11.5,
      null,
    ),
    xl: null,
    "2xl": null,
  ),
  ".p2-desc": (
    sm: (
      -1,
      0.75,
      null,
      12,
      var(--clr-cool-blue-400),
    ),
    md: (
      null,
      0.875,
      null,
      12.5,
      null,
    ),
    lg: (
      null,
      null,
      null,
      13,
      null,
    ),
    xl: null,
    "2xl": null,
  ),
  ".nav-link": (
    sm: (
      -1,
      0.625,
      null,
      6.5,
      var(--clr-cool-blue-500),
    ),
    md: (
      null,
      null,
      null,
      7,
      null,
    ),
    lg: (
      null,
      null,
      null,
      7,
      null,
    ),
    xl: null,
    "2xl": null,
  ),
  ".cta": (
    sm: (
      0,
      0.625,
      0.1,
      3,
      var(--clr-cool-blue-600),
    ),
    md: (
      null,
      null,
      null,
      3,
      null,
    ),
    lg: (
      null,
      null,
      null,
      3,
      null,
    ),
    xl: null,
    "2xl": null,
  ),
  // ".p3": (sm: (-2, .5, null, null), md: (-2, .5, null, null), lg: (-2, .5, null, null), xl: null, 2xl: null),
);

// -------------------- Watermark Variables Prefixes
$watermark-prefixes: (
  "ui-designer": "/assets/img/ui-designer-watermark-vector",
  "my-work": "/assets/img/my-work-watermark-vector",
  "feedback": "/assets/img/feedback-watermark-vector",
  "about-me": "/assets/img/about-me-watermark-vector",
);

// -------------------- Work Section Image Sizes
$img-sizes: (
  1: (
    md: (
      406,
      462,
    ),
    xl: (
      510,
      600,
    ),
  ),
  2: (
    md: (
      462,
      322,
    ),
    xl: (
      570,
      420,
    ),
  ),
  3: (
    md: (
      490,
      308,
    ),
    xl: (
      615,
      405,
    ),
  ),
  4: (
    md: (
      392,
      462,
    ),
    xl: (
      480,
      600,
    ),
  ),
  5: (
    md: (
      420,
      378,
    ),
    xl: (
      540,
      450,
    ),
  ),
);

// -------------------- Z-index Variables
$z-index-n2: -2;
$z-index-n1: -1;
$z-index-base: 1;
$z-index-md: 5;
$z-index-lg: 10;
$z-index-xl: 15;
$z-index-2xl: 20;

// -------------------- Case-study Variables
$case-study-spacings: (
  1: (
    sm: 0.75,
    md: 0.25,
    lg: 0.25,
  ),
  2: (
    sm: 1,
    md: 1,
    lg: 1,
  ),
  3: (
    sm: 1.25,
    md: 1.5,
    lg: 3,
  ),
  4: (
    sm: 2.5,
    md: 3,
    lg: 5,
  ),
  5: (
    sm: 4.5,
    md: 5.5,
    lg: 7,
  ),
);

// -------------------- CSS Variables
:root {
  --clr-primary-500: #{$clr-primary-500};
  --clr-shade-light: #{$clr-shade-light};
  --clr-darkpink-500: #{$clr-darkpink-500};
  --clr-cool-blue-100: #{$clr-cool-blue-100};
  --clr-cool-blue-200: #{$clr-cool-blue-200};
  --clr-cool-blue-300: #{$clr-cool-blue-300};
  --clr-cool-blue-400: #{$clr-cool-blue-400};
  --clr-cool-blue-500: #{$clr-cool-blue-500};
  --clr-cool-blue-600: #{$clr-cool-blue-600};

  // --------- Case study variables
  // GBS Design
  --clr-primary-gbs: hsla(218, 80%, 54%, 1);

  // Geodaka Redesign
  --clr-primary-geodaka: hsla(231, 64%, 58%, 1);
}
