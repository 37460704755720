// -------------------- Partials Imports
@use "../abstracts/variables" as var;
@use "../abstracts/functions" as func;
@use "../abstracts/mixins" as mix;

.header {
  position: fixed;
  width: 100%;
  top: func.convert(var(--nav-top-hero-top));
  z-index: var.$z-index-lg;

  .navbar-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: func.convert(0.375);

    @include mix.media(var.$mobile-sm, "max-width") {
      justify-content: center;
      flex-direction: column;
    }
  }

  .amj-logo {
    width: func.convert(1.5);

    @include mix.media(var.$tablet-portrait) {
      width: func.convert(2);
    }
  }

  .nav {
    &__list {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: func.convert(7);

      @include mix.media(var.$tablet-portrait) {
        width: func.convert(9);
      }

      @include mix.media(var.$tablet-landscape) {
        width: func.convert(12);
      }
    }

    &__link {
      &-icon {
        display: flex;
        align-items: center;
        isolation: isolate;

        --icon-width: 14;
        @include mix.media(var.$tablet-portrait) {
          --icon-width: 16;
        }
        @include mix.media(var.$tablet-landscape) {
          --icon-width: 18;
        }

        &__icon {
          width: func.pxToRem(var(--icon-width));
        }

        // Adding hover animation only on pointer devices with no preference on motion
        @include mix.media-hover-motion {
          p {
            transform: translateX(calc(func.pxToRem(var(--icon-width)) / 2));
            transition: transform 200ms ease-in-out, color 150ms ease-in-out;
            z-index: var.$z-index-base;
          }

          &__icon {
            opacity: 0;
            transform: translateX(calc(func.pxToRem(var(--icon-width)) * -1));
            transition: opacity 150ms ease-in-out, transform 200ms ease-in-out;

            svg path {
              transition: fill 150ms ease-in-out;
            }
          }

          &:is(:hover, :focus-visible) :is(p, &__icon) {
            transform: translateX(0);
            opacity: 1;
          }
        }
      }
    }
  }
}
