// This CSS reset is inspired by Andy bell and Josh Comeau's CSS reset.
// Andy bell's css reset: https://piccalil.li/blog/a-modern-css-reset/
// Josh Comeau's css reset: https://www.joshwcomeau.com/css/custom-css-reset/

/* Use a more-intuitive box-sizing model. */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
* {
  margin: 0;
  padding: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role="list"],
ol[role="list"] {
  list-style: none;
}

/* Set core root defaults */
html {
  scroll-behavior: smooth;
}

/*
  Set core body defaults,
  Typographic tweaks!
    - Add accessible line-height
    - Improve text rendering
*/
body {
  min-height: 100vh;
  line-height: 1.5;
  text-rendering: optimizeSpeed;
  -webkit-font-smoothing: antialiased; /* Webkit */
  -moz-osx-font-smoothing: grayscale; /* Firefox */
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Improve media defaults */
img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Avoid text overflows */
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }

  // *,
  // *::before,
  // *::after {
  //   animation-duration: 0.01ms !important;
  //   animation-iteration-count: 1 !important;
  //   transition-duration: 0.01ms !important;
  //   scroll-behavior: auto !important;
  // }
}
