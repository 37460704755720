// -------------------- Partials Imports
@use "../abstracts/variables" as var;
@use "../abstracts/functions" as func;
@use "../abstracts/mixins" as mix;

.cursor-trailer {
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  will-change: transform;
  contain: layout style size;
  z-index: var.$z-index-2xl;
  mix-blend-mode: multiply;

  &__circle {
    width: func.convert(1);
    height: func.convert(1);
    border: func.pxToRem(2) solid var(--clr-cool-blue-500);
    background-color: var(--clr-cool-blue-200);
    border-radius: 50%;
    will-change: transform;
    pointer-events: none;
    transform: translate(-50%, -50%) scale(0);
    transition: transform 300ms ease-in-out, scale 300ms ease-in-out;
  }

  &.hide {
    > .cursor-trailer__circle {
      transform: translate(-50%, -50%) scale(0) !important;
      scale: 0 !important;
    }
  }

  @include mix.media-hover("none") {
    display: none;
  }
  @include mix.media-motion("reduce") {
    display: none;
  }
}
