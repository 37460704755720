// -------------------- SASS Imports
@use "sass:color";

// -------------------- Partials Imports
@use "../abstracts/variables" as var;
@use "../abstracts/functions" as func;
@use "../abstracts/mixins" as mix;

@keyframes spin {
  to {
    transform: rotate(var(--turns, 1turn));
  }
}

@keyframes slideEnterX {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0%);
  }
}
@keyframes bgSlideX {
  to {
    background-position-x: -5000px;
  }
}
@keyframes bgSlideXReverse {
  to {
    background-position-x: 5000px;
  }
}

// -------------------- Target Border
.target {
  &__border {
    position: relative;
    --dashoffset: var(--dasharray, 1000px);
  }
  &__svg-wrapper {
    position: absolute;
    user-select: none;
    pointer-events: none;
    --sizeVal: var(--size, #{func.pxToRem(-20)});
    top: var(--sizeVal);
    right: var(--sizeVal);
    left: var(--sizeVal);
    bottom: var(--sizeVal);

    svg {
      rect {
        transform-box: fill-box;
        transform: translate(-50%, -50%);
        width: calc(100% - var(--sizeVal) * -1 * 0.8);
        height: calc(100% - var(--sizeVal) * -1 * 0.8);

        --clr-stroke: #{color.scale(var.$clr-primary-500, $alpha: -30%)};
        stroke: var(--clr-stroke);
        stroke-width: 3;
        stroke-linecap: round;
        stroke-dasharray: var(--dasharray);
        stroke-dashoffset: calc(-1 * var(--dashoffset));
      }
    }
  }

  &:target {
    // If the ".target__border" is inside the ".target",
    // then just replace the "& ~ .target__border" selector
    // with "&.target__border" - If the ".target" and ".target__border" classes are on the same element
    // or "& .target__border" (Notice I added spce in between) - If the ".target" is the parent of the ".target__border"
    & ~ .target__border {
      .target__svg-wrapper {
        svg {
          rect {
            animation: border-spin var(--duration, 2s) var(--delay, 1s)
              ease-in-out !important;
          }
        }
      }
    }
  }
}

@keyframes border-spin {
  to {
    stroke-dashoffset: var(--dashoffset);
  }
}

@keyframes appearIn {
  0%,
  100% {
    opacity: 0;
  }
  20%,
  80% {
    opacity: 1;
  }
}

@keyframes crown-shadow-scale-up-down {
  to {
    transform: translate(var(--ds-translate-x), var(--ds-translate-y))
      rotate(var(--ds-rotate)) scale(var(--ds-scale-to, 0.8));

    filter: blur(calc(var(--ds-blur) * var(--ds-blur-to, 1.5)));
  }
}

@keyframes crown-up-n-down {
  to {
    transform: translate(
        calc(var(--crown-translate-x) * var(--crown-translate-x-to, 1.2)),
        calc(var(--crown-translate-y) * var(--crown-translate-y-to, 1.2))
      )
      rotate(var(--crown-rotate));
  }
}

@keyframes slideOut {
  to {
    transform: scaleX(0);
  }
}

@keyframes my-css-loader {
  0% {
    transform: translate(-50%, -50%) scale(0);
    opacity: 0;
  }
  4.9% {
    transform: translate(-50%, -50%) scale(0);
    opacity: 0;
  }
  5% {
    transform: translate(-50%, -50%) scale(0);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
  }
}
