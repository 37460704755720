// -------------------- Partials Imports
@use "../abstracts/variables" as var;
@use "../abstracts/functions" as func;
@use "../abstracts/mixins" as mix;

.case-study {
  &__hero-sec {
    padding-top: func.convert(var(--combined-mt));
  }

  &__hero-content {
    position: relative;
    --mt: #{func.convert(0.25)};
    margin-top: var(--mt);

    @include mix.media(var.$tablet-portrait) {
      --mt: func.convert(0.5);
    }
  }

  &__hero-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: var.$z-index-n1;
    pointer-events: none;
    user-select: none;
    background-color: var(--clr-cool-blue-100);

    img {
      max-width: unset;
      width: 100%;
      height: func.convert(14);
      object-fit: cover;
      object-position: center;

      @include mix.media(var.$tablet-portrait) {
        height: func.convert(18);
      }
      @include mix.media(var.$tablet-landscape) {
        height: func.convert(22);

        @include mix.media(var.$tablet-mh-sm, "max-height") {
          height: func.convert(18);
        }
      }
    }
  }

  &__hero-bg-gradients {
    --offset: 1px;

    &__top,
    &__bottom {
      position: absolute;
      left: 0;
      right: 0;
      height: calc(var(--height) + var(--offset));
    }
    &__top {
      --top: 0px;
      top: calc(var(--top) - var(--offset));

      --height: #{func.pxToRem(70)};
      background: linear-gradient(
        180deg,
        #ffffff 12.69%,
        rgba(255, 255, 255, 0) 100%
      );
    }
    &__bottom {
      --bottom: 0px;
      bottom: calc(var(--bottom) - var(--offset));
      --height: #{func.pxToRem(286)};

      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.8) 25.52%,
        #ffffff 100%
      );
    }

    @include mix.media(var.$tablet-portrait) {
      &__top {
        --height: #{func.pxToRem(96)};

        background: linear-gradient(
          180deg,
          #ffffff 12.69%,
          rgba(255, 255, 255, 0) 100%
        );
      }
      &__bottom {
        --height: #{func.pxToRem(348)};

        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0.8) 32.81%,
          #ffffff 100%
        );
      }
    }
    @include mix.media(var.$tablet-landscape) {
      &__top {
        --height: #{func.pxToRem(120)};

        background: linear-gradient(
          180deg,
          #ffffff 12.69%,
          rgba(255, 255, 255, 0) 100%
        );
      }
      &__bottom {
        --height: #{func.pxToRem(420)};

        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0.8) 32.81%,
          #ffffff 100%
        );
      }
    }
  }

  &__hero-headings {
    padding-top: func.convert(6);

    @include mix.media(var.$tablet-portrait) {
      padding-top: func.convert(8.5);
    }
    @include mix.media(var.$tablet-landscape) {
      padding-top: func.convert(13.5);

      @include mix.media(var.$tablet-mh-sm, "max-height") {
        padding-top: func.convert(8.5);
      }
    }

    p {
      margin-top: func.convert(0.5);

      @include mix.media(var.$tablet-portrait) {
        margin-top: func.convert(0.25);
      }
      @include mix.media(var.$tablet-landscape) {
        margin-top: func.convert(0.5);
      }
    }
  }

  &__project-summary {
    margin-top: func.convert(2.5);

    @include mix.media(var.$tablet-portrait) {
      margin-top: func.convert(4);
    }

    @include mix.media(var.$tablet-landscape) {
      margin-top: func.convert(6.5);
    }
  }

  &__info {
    &-desc {
      @include mix.media(var.$tablet-portrait) {
        margin-top: func.convert(0.25);
      }

      &--wrapper {
        display: flex;
        flex-direction: column;
        gap: func.convert(0.75);

        @include mix.media(var.$tablet-portrait) {
          gap: func.convert(1);
        }
      }
    }
  }

  &__grid {
    display: grid;
    gap: func.convert(1.25);

    @include mix.media(var.$tablet-portrait) {
      gap: func.convert(1.5) min(5vw, func.convert(3));

      &--col-2 {
        grid-template-columns: repeat(auto-fill, func.convert(9.5));
      }
    }

    @include mix.media(var.$tablet-landscape) {
      gap: func.convert(3) func.convert(4);

      &--col-2 {
        grid-template-columns: repeat(2, func.convert(10));
      }
    }

    @include mix.media(var.$desktop) {
      gap: func.convert(3) func.convert(4);

      &--col-2 {
        grid-template-columns: repeat(2, func.convert(12));
      }
    }

    @include mix.media(var.$desktop-lg) {
      &--col-2 {
        grid-template-columns: repeat(2, func.convert(17));
      }
    }
  }

  &__tooltip {
    display: inline-block;
    vertical-align: top;

    --clr-tooltip-text: var(--clr-darkpink-500);

    button {
      color: var(--clr-tooltip-text);
      position: relative;
      display: inline-block;

      --tooltip-tap-area-x: #{func.convert(0.25)};
      --tooltip-tap-area-y: #{func.convert(0.25)};

      &::after {
        content: "";
        position: absolute;
        top: calc(-1 * var(--tooltip-tap-area-y));
        bottom: calc(-1 * var(--tooltip-tap-area-y));
        right: calc(-1 * var(--tooltip-tap-area-x));
        left: calc(-1 * var(--tooltip-tap-area-x));
      }

      @include mix.media(var.$tablet-portrait) {
        --tooltip-tap-area-x: #{func.convert(0.375)};
      }
    }

    &--question {
      button {
        cursor: help;
      }
    }
  }

  &__heading-bg {
    position: relative;
    isolation: isolate;
    overflow: hidden;

    padding: func.convert(1) 0;
    height: func.convert(16);
    background-color: var(--clr-cool-blue-100);

    @include mix.media(var.$tablet-portrait) {
      height: func.convert(13);
    }
    @include mix.media(var.$tablet-landscape) {
      height: func.convert(15);
      padding: func.convert(1.125) 0;
    }
    @include mix.media(var.$desktop) {
      height: func.convert(18);
      padding: func.convert(2) 0;
    }

    & > .case-study-bg-img {
      position: absolute;
      top: 0;
      left: 50%;
      width: min(100%, 25rem);
      height: 100%;
      z-index: var.$z-index-n1;

      background-size: contain;
      background-repeat: no-repeat;
      background-position: bottom right;

      --translate-x-bg: 10%;
      --translate-y-bg: 15%;
      --translate-bg: calc(
          -50% + (var(--translate-x-bg, 0px) * var(--multiple-x, 1))
        ),
        calc(var(--translate-y-bg, 0px) * var(--multiple-y, 1));

      --rotate-bg: -15deg;
      --scale-bg: 1.5;

      transform: translate(var(--translate-bg, 0px))
        rotate(calc(var(--rotate-bg, 0deg) * var(--multiple-rotate, 1)))
        scale(calc(var(--scale-bg, 1) * var(--multiple-scale, 1)));

      @include mix.media(var.$mobile-sm, "max-width") {
        --translate-x-bg: 5%;
        --translate-y-bg: 0.5%;
      }
      @include mix.media(var.$tablet-portrait) {
        width: min(100%, 30rem);

        --translate-x-bg: 20%;
        --translate-y-bg: 50%;
        --rotate-bg: -30deg;
      }
      @include mix.media(var.$tablet-landscape) {
        width: min(100%, 40rem);

        --translate-x-bg: 5%;
        --translate-y-bg: 60%;
        --scale-bg: 1.7;
      }
      @include mix.media(var.$desktop) {
        width: min(100%, 50rem);

        --translate-x-bg: 0%;
        --translate-y-bg: 70%;
      }
    }

    &--bg-right {
      & > .case-study-bg-img {
        background-position: bottom right;
        --multiple-x: 1;
        --multiple-y: 1;
        --multiple-rotate: 1;
      }
    }

    &--bg-left {
      & > .case-study-bg-img {
        background-position: bottom left;
        --multiple-x: -1;
        --multiple-y: 1;
        --multiple-rotate: -1;
      }
    }

    &--bg-small {
      padding: func.convert(0.5) 0;
      height: func.convert(13);

      @include mix.media(var.$tablet-portrait) {
        height: func.convert(13);
        padding: func.convert(1) 0;
      }
      @include mix.media(var.$tablet-landscape) {
        height: func.convert(13);
        padding: func.convert(1.125) 0;
      }
      @include mix.media(var.$desktop) {
        height: func.convert(15);
        padding: func.convert(2) 0;
      }

      & > .case-study-bg-img {
        --multiple-x: -2.5;
        --multiple-y: 2.5;
        --multiple-scale: 1.125;

        @include mix.media(var.$mobile-sm, "max-width") {
          --multiple-x: -1.5;
          --multiple-y: 15;
        }
        @include mix.media(var.$tablet-portrait) {
          --multiple-x: -1.375;
          --multiple-y: 1.375;
        }
        @include mix.media(var.$tablet-landscape) {
          --multiple-x: -1.375;
          --multiple-y: 1.375;
        }
      }

      // Use this modifier class for less offsetting of the bg image
      &--v2 {
        & > .case-study-bg-img {
          --multiple-x: -1;
          --multiple-y: 1;

          @include mix.media(var.$mobile-sm, "max-width") {
            --multiple-x: -1;
            --multiple-y: 1;
          }
          @include mix.media(var.$tablet-portrait) {
            --multiple-x: -1;
            --multiple-y: 1;
          }
          @include mix.media(var.$tablet-landscape) {
            --multiple-x: -1;
            --multiple-y: 1.375;
          }
        }
      }
    }

    &--bg-only {
      height: func.convert(13);
      padding: unset;

      @include mix.media(var.$tablet-portrait) {
        height: func.convert(13);
        padding: unset;
      }
      @include mix.media(var.$tablet-landscape) {
        height: func.convert(15);
        padding: unset;
      }
      @include mix.media(var.$desktop) {
        height: func.convert(18);
        padding: unset;
      }

      & > .case-study-bg-img {
        width: 100%;
        background-position: center;
        --multiple-x: 0;
        --multiple-y: 0;
        --multiple-rotate: 0;
      }
    }
  }
}
