// -------------------- Partials Imports
@use "../abstracts/variables" as var;
@use "../abstracts/functions" as func;
@use "../abstracts/mixins" as mix;

// -------------------- Container
.container-lg {
  --container-lg-max-width: 1440;
  max-width: func.pxToRem(var(--container-lg-max-width));
  margin: 0 auto;
}

.container-md {
  --multiple: 1;
  --padding-x: #{func.convert(var(--multiple))};

  padding: 0 var(--padding-x);
  margin: 0 auto;

  @include mix.media(var.$mobile-sm, "max-width") {
    --padding-x: min(5vw, #{func.convert(var(--multiple))});
  }

  @include mix.media(var.$tablet-portrait) {
    --max-width: 22;
    --padding-x: #{func.convert(var(--multiple))};

    max-width: calc(
      func.convert(var(--max-width)) + func.convert(var(--multiple)) * 2
    );
  }

  @include mix.media(var.$tablet-landscape) {
    --multiple: 2;
    --max-width: 42;
    --padding-x: #{func.convert(var(--multiple))};
  }
}
